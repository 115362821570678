
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import TrancheDrawer from "@/layout/header/partials/settings/TrancheDrawer.vue";
import EditTrancheDrawer from "@/layout/header/partials/settings/EditTrancheDrawer.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: "Tranche Info",
  components: {
    Datatable,
    TrancheDrawer,
    EditTrancheDrawer,
  },
  data() {
    return {
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "SL",
          key: "tranche_no",
          sortable: true,
        },

        {
          name: "Parent Tranche",
          key: "parent_tranche_id",
          sortable: true,
        },
        {
          name: "Name",
          key: "label",
          sortable: true,
        },
        {
          name: "Short Name",
          key: "short_name",
          sortable: true,
          width: "140px",
        },
        {
          name: "Start Date",
          key: "start_date",
          sortable: true,
          width: "140px",
        },
        {
          name: "End Date",
          key: "end_date",
          sortable: true,
          width: "140px",
        },
        {
          name: "Donor Agencies",
          key: "donor_agencies",
          sortable: true,
          width: "140px",
        },
        {
          name: "Amount",
          key: "amount",
          sortable: true,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      trancheKey: 0,
    };
  },
  async created() {
    await this.getData();
    // Object.assign(this.tableData, this.lists);
    this.emitter.on("tranche-added", async () => {
      await this.getData();

      // Object.assign(this.tableData, this.lists);
    });
  },
  methods: {
    async getData() {
      await ApiService.get("configurations/tranche/list")
        .then((response) => {
          this.lists = response.data.data;
          this.trancheKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    // searchItems() {
    //   if (this.search !== "") {
    //     let results: never[] = [];
    //     for (let j = 0; j < this.tableData.length; j++) {
    //       if (this.searchingFunc(this.tableData[j], this.search)) {
    //         results.push(this.tableData[j]);
    //       }
    //     }
    //     this.tableData.splice(0, this.tableData.length, ...results);
    //   } else {
    //     Object.assign(this.tableData, this.lists);
    //   }
    // },

    // searchingFunc(obj, value) {
    //   for (let key in obj) {
    //     if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
    //       if (obj[key].indexOf(value) != -1) {
    //         return true;
    //       }
    //     }
    //   }
    //   return false;
    // },
    view(id) {
      this.$router.push({
        name: "tranche-view",
        params: id,
      });
    },
    edit(data) {
      this.emitter.emit("edit-tranche-data", data);
    },

    Delete(id) {
      let user_id = VueCookieNext.getCookie('_seip_user');
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('configurations/tranche/delete?id='+ id +
            '&responsible_user_id='+ user_id.id)
            .then((response) => {
              this.emitter.emit("tranche-added", true);
              Swal.fire("Deleted!", response.data.message, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
